<template>
  <div class="centerView deliverySearch">
    <a-form :model="formData" :rules="rules" ref="formRef" layout="inline">
      <a-form-item name="shippingSn">
        <a-input allowClear size="large" v-model:value="formData.shippingSn" :placeholder="t('deliveryManage.filterForm.shippingSn')"></a-input>
      </a-form-item>
      <a-form-item name="expressCode">
        <a-input allowClear size="large" v-model:value="formData.expressCode" :placeholder="t('deliveryManage.filterForm.expressCode')"></a-input>
      </a-form-item>
      <a-form-item name="snKeywords">
        <a-input allowClear size="large" v-model:value="formData.snKeywords" :placeholder="t('deliveryManage.filterForm.orderTitle')"></a-input>
      </a-form-item>
      <a-form-item name="paymentStartTime">
        <a-range-picker v-model:value="date" format="YYYY-MM-DD HH:mm:ss" valueFormat="YYYY-MM-DD HH:mm:ss" :showTime="showTime" :placeholder="['Begin Time', 'End Time']" size="large">
          <template #suffixIcon>
            <img style="width: 15px; height: 15px; display: flex" src="@/assets/images/table/timePickIcon.png" alt="" />
          </template>
        </a-range-picker>
      </a-form-item>
      <a-form-item>
        <a-space :size="26">
          <a-button type="primary" size="large" @click="search">{{ t('search') }}</a-button>
          <div class="form-item-button gray" @click="reset">{{ t('reset') }}</div>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>
<script setup>
import { ref, reactive, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
const { t } = useI18n()
const emits = defineEmits(['searchFn'])
const formRef = ref()
//表单校验
const rules = []
const formData = reactive({
  shippingSn: null, //名称/编码/SKU
  expressCode: null, //名称/编码/SKU
  startTime: null, //时间
  endTime: null, //时间
  snKeywords: null, //发货单号、订单号搜索条件
  page: 1,
  size: 10,
})
const date = ref([])
const showTime = {
  hideDisabledOptions: true,
  defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
}
//搜索事件
const search = () => {
  const [startTime = null, endTime = null] = date.value || []
  emits('searchFn', { ...formData, startTime, endTime })
}
//重置事件
const reset = () => {
  formRef.value.resetFields()
  formData.page = 1
  date.value = []
  search()
}
</script>
<style lang="less">
.deliverySearch {
}
</style>
