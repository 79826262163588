import { transformColumnsI18n } from '@/language'
const columns = [
  {
    title: 'deliveryManage.table.productPicList',
    dataIndex: 'productPicList',
    key: 'productPicList',
    width: 300,
    slots: {
      customRender: 'productPicList',
    },
  },
  {
    title: 'deliveryManage.table.shippingSn',
    dataIndex: 'shippingSn',
    key: 'shippingSn',
    width: 150,
    slots: {
      customRender: 'shippingSn',
    },
  },
  {
    title: 'deliveryManage.table.receivingWarehouseName',
    dataIndex: 'receivingWarehouseName',
    key: 'receivingWarehouseName',
    width: 150,
    slots: {
      customRender: 'receivingWarehouseName',
    },
  },
  {
    title: 'deliveryManage.table.deliveryQuantity',
    dataIndex: 'deliveryQuantity',
    key: 'deliveryQuantity',
    width: 100,
    slots: {
      customRender: 'deliveryQuantity',
    },
  },
  {
    title: 'deliveryManage.table.deliveryTime',
    dataIndex: 'deliveryTime',
    key: 'deliveryTime',
    width: 140,
    slots: {
      customRender: 'deliveryTime',
    },
  },
  {
    title: 'deliveryManage.table.createAt',
    dataIndex: 'createAt',
    key: 'createAt',
    width: 140,
    slots: {
      customRender: 'createAt',
    },
  },
  {
    title: 'deliveryManage.table.createName',
    dataIndex: 'createName',
    key: 'createName',
    width: 80,
    slots: {
      customRender: 'createName',
    },
  },
  {
    title: 'deliveryManage.table.expressListBos',
    dataIndex: 'expressListBos',
    key: 'expressListBos',
    width: 120,
    slots: { customRender: 'expressListBos' },
  },
  {
    title: 'deliveryManage.table.operate', //operate
    key: 'operate',
    fixed: 'right',
    align: 'center',
    width: 260,
    slots: { customRender: 'operate' },
  },
]
export function exportTable(e) {
  let tableObj = JSON.parse(JSON.stringify(columns))
  //1-待发货  2-已发货  3-已完成  4-接收异常
  let arrList = []
  if (e == '1') {
    arrList = ['productPicList', 'shippingSn', 'receivingWarehouseName', 'deliveryQuantity', 'createAt', 'createName', 'expressListBos', 'operate']
  }
  if (e == '2') {
    arrList = ['productPicList', 'shippingSn', 'receivingWarehouseName', 'deliveryQuantity', 'deliveryTime', 'createName', 'expressListBos']
  }
  if (e == '3') {
    arrList = ['productPicList', 'shippingSn', 'receivingWarehouseName', 'deliveryQuantity', 'createName', 'expressListBos']
  }
  console.log(tableObj, 'tableObj---tableObj')
  let list = []
  arrList.map((item) => {
    const findItems = columns.find((val) => val.key == item)
    console.log(findItems, 'findItems----findItems')
    if (findItems) {
      list.push(findItems)
    }
  })
  return transformColumnsI18n(list)
}
