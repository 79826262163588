<template>
  <div class="contentView">
    <searchBox @searchFn="searchFn"></searchBox>
    <tableBox ref="tableRef"></tableBox>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import searchBox from './cpn/deliverySearch.vue';
import tableBox from './cpn/deliveryTable.vue';
const tableRef = ref();
const searchFn = (payload) => {
  tableRef.value.action(payload);
};
</script>
<style lang="less" scoped></style>
