<template>
  <div class="centerView">
    <div class="manageNav">
      <a-tabs v-model:activeKey="activeKey" @change="tabsChange" size="small">
        <a-tab-pane :key="item.key" :tab="t(item.title) + `(${item.num})`" v-for="item in tabList"></a-tab-pane>
      </a-tabs>
      <div class="btnExport">
        <a-button type="primary" size="large" :disabled="selectedRowKeysList.length > 0 ? false : true" @click="exportClick">导出订单明细</a-button>
      </div>
    </div>
    <div class="tableBoxs">
      <a-spin tip="Loading..." :spinning="spinRef">
        <a-table
          v-if="!tableLoading"
          :dataSource="tableData"
          :columns="columns"
          :loading="tableLoading"
          :pagination="false"
          size="small"
          :row-key="(r) => r.id"
          :scroll="{ x: 1300 }"
          :row-selection="rowSelection"
        >
          <!-- 操作 -->
          <template #operate="{ text }">
            <a-space :size="20">
              <!-- <a-button type="primary" class="padding-horizontal-12" v-if="activeKey == '1'" @click="toDetail('add', text)">{{ t('deliveryManage.table.delivery') }}</a-button> -->
              <!-- <a-button type="primary" @click="toDetail('add', text)">{{ t('download') }}</a-button> -->
              <div class="table-button dark padding-horizontal-20" v-if="activeKey == '1'" @click="toDetail('add', text)">{{ t('deliveryManage.table.delivery') }}</div>
              <a-button type="primary" class="padding-horizontal-20" v-if="activeKey == '1'" @click="printFn(text)">{{ t('deliveryManage.table.print') }}</a-button>
              <!-- <div class="table-button dark padding-horizontal-20" v-if="activeKey != '1'" @click="exportShippingFn(text)">
              <LoadingOutlined v-if="exportShippingSubmitting" />
              {{ t('download') }}
            </div> -->
            </a-space>
          </template>
          <!-- 商品 -->
          <template #productPicList="{ record, text }">
            <ImgGallery v-if="text && text.length" :urls="text" @viewMore="toDetail('view', record)" />
            <span v-else>-</span>
          </template>
          <!-- 发货号 -->
          <template #shippingSn="{ record, text }">
            <span style="color: #050505; text-decoration: underline; cursor: pointer" @click="toDetail('view', record)">{{ text || '-' }}</span>
          </template>
          <template #receivingWarehouseName="{ text }">
            {{ text || '-' }}
          </template>
          <template #deliveryQuantity="{ record, text }">
            <span v-if="activeKey == '1'">{{ text || 0 }}</span>
            <a-space v-else direction="vertical">
              <span>{{ t('deliveryManage.table.send') }}：{{ text || 0 }}</span>
              <span> {{ t('deliveryManage.table.receive') }}：{{ record.receiptQuantity || 0 }} </span>
              <span v-if="activeKey == '3'">
                {{ t('deliveryManage.table.unqualified') }}：<span :style="{ color: record.unqualifiedQuantity ? '#ff6700' : '' }">{{ record.unqualifiedQuantity || 0 }}</span>
              </span>
            </a-space>
          </template>
          <template #createAt="{ text }">
            {{ text || '-' }}
          </template>
          <template #deliveryTime="{ text }">
            {{ text || '-' }}
          </template>
          <template #createName="{ text }">
            {{ text || '-' }}
          </template>
          <!-- 等待时长 -->
          <!-- <template #ii>
          <div style="color: #f00"><span style="margin-right: 6px">10</span>H</div>
        </template> -->
          <!-- 物流信息 -->
          <template #expressListBos="{ record, text }">
            <a-space direction="vertical" v-if="text[0]" style="width: 100%">
              <a-space style="white-space: nowrap; width: 100%; display: flex; justify-content: space-between">
                <span>{{ `【${text[0].expressName || '-'}】` }}</span>
                <img v-if="activeKey == '2'" @click="addExpressFn(record)" src="@/assets/supplier/images/deliveryManage/express-add-02-icon.png" style="width: 24px; cursor: pointer" />
              </a-space>
              <span class="addCode" @click="showAddressMOdal(text[0].expressCode)">{{ text[0].expressCode || '-' }}</span>
            </a-space>
            <span v-else>-</span>
          </template>
        </a-table>
      </a-spin>
      <TableSkeleton v-if="tableLoading" />
      <pagination mt lable="" :data="list" @change="onPageChange" @sizeChange="onSizeChange"></pagination>
    </div>
    <AddExpressModal ref="AddExpressModalRef" v-model:modalVisible="modalVisible" v-model:submitting="submitting" @submit="(e) => createExpressFn(e)"></AddExpressModal>
    <a-drawer v-model:visible="visibleDetail" width="90% !important" class="custom-class" title="" placement="right">
      <detailCpn ref="deliveryDetailRef" @closeModalUpdateList="closeModalUpdateList" />
    </a-drawer>
    <a-modal title="物流信息" v-model:visible="addressVisible" :footer="null">
      <a-timeline>
        <a-timeline-item v-for="(item, index) in logistics" :key="index">
          <div class="nodeTime">{{ item.nodeTime }}</div>
          <div><GlobalOutlined style="margin-right: 10px" />{{ item.nodeLocation }}</div>
          <div class="nodeDesc">{{ item.nodeDesc }}</div>
          <!-- <p>Solve initial network problems 3 2015-09-01</p> -->
        </a-timeline-item>
      </a-timeline>
    </a-modal>
  </div>
</template>
<script setup>
/* eslint-disable */
import { ref, watch, onActivated, onMounted, computed, unref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { LoadingOutlined, GlobalOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import { formatImg } from '@/utils/util.js'
import pagination from '@/components/pagination.vue'
import TableSkeleton from '@/views/supplier/components/TableSkeleton'
import ImgGallery from '@/views/supplier/components/ImgGallery'
import AddExpressModal from '@/views/supplier/components/AddExpressModal'
import detailCpn from '../deliveryManageDetail.vue'
import { exportTable } from '../config/deliveryTableConfig'

import { getDeliveryManageTableData, createExpress, exportShipping, getDeliveryManageTotalsData, expressLogsGet } from '@/api/supplier/orderManagement/deliveryManage'
import { createExportTask, getTaskResult } from '@/api/supplier/data-workers'
const { t, locale } = useI18n()
const router = useRouter()
const route = useRoute()
const visibleDetail = ref(false) //控制详情弹框
const deliveryDetailRef = ref() //详情绑定元素
const spinRef = ref(false)

//tab绑定
const activeKey = ref('1')
const tabList = ref([
  { key: '1', title: 'deliveryManage.tabsPane.1', num: 0, index: 0, flag: 'waittingShippedNum' },
  { key: '2', title: 'deliveryManage.tabsPane.2', num: 0, index: 10, flag: 'alreadyShippedNum' },
  { key: '3', title: 'deliveryManage.tabsPane.3', num: 0, index: 20, flag: 'shippedFinishedNum' },
])

function getDeliveryManageTotalsDataFn() {
  getDeliveryManageTotalsData({})
    .then((res) => {
      if (res.code === 200) {
        tabList.value = tabList.value.map((item) => {
          item.num = res.data[item.flag] || 0
          return item
        })
      } else {
        // message.error(res.msg)
      }
    })
    .catch(() => {})
}
// getDeliveryManageTotalsDataFn();

//表格绑定标题
const columns = ref([])
columns.value = exportTable(activeKey.value)
//表格数据
const tableData = ref([])
//表格分页
const list = ref()
const formData = ref({})
//分页数据
const page = ref({
  page: 1,
  size: 10,
})
const tableLoading = ref(false)

function action(payloadObj) {
  formData.value = payloadObj
  getTableData()
}

// 选中的行key列表
const selectedRowKeysList = ref([])
// 选中的行数据
const selectedRowList = ref([])
const rowSelection = computed(() => {
  return {
    // getCheckboxProps: record => ({
    //   disabled: dataSource.value.some(it => it.attributeId == record.id),
    // }),
    fixed: true,
    selectedRowKeys: unref(selectedRowKeysList),
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows, 'selectedRowKeys, selectedRows------selectedRowKeys, selectedRows')
      selectedRowKeysList.value = selectedRowKeys
      selectedRowList.value = selectedRows
    },
    hideDefaultSelections: true,
  }
})

//点击导出订单明细
const downloadToken = ref() //导出明细token
const exportClick = async () => {
  downloadToken.value = ''
  let selelist = {
    shippingIds: selectedRowKeysList.value.map(String),
  }
  let payload = {
    projectCode: 'SUPPLIER_ORDER_SHIPPING_DETAIL_EXPORT',
    dataParam: JSON.stringify(selelist),
  }
  const { code, data } = await createExportTask(payload)
  if (code == 200) downloadToken.value = data
  spinRef.value = true
  await getTaskResultFn(data)
}
const countNum = ref('')
//下载列表轮训
const getTaskResultFn = () => {
  getTaskResult({ taskToken: downloadToken.value })
    .then((res) => {
      if (res.code == 200) {
        // 任务状态
        //   0、待处理
        //   1、成功
        //   9、失败
        if (countNum.value > 5 && res.data.state == 0) {
          countNum.value = 0
          spinRef.value = false
          return message.error('下载失败，请稍后重试')
        }
        switch (res.data.state) {
          case 0:
            countNum.value++
            setTimeout(getTaskResultFn, 2000)
            break
          case 1:
            countNum.value = 0
            spinRef.value = false
            selectedRowKeysList.value = []
            selectedRowList.value = []
            res.data.fileUrl && (window.location.href = res.data.fileUrl)
            break
          case 9:
            countNum.value = 0
            spinRef.value = false
            message.error(res.data.resultMessage || '下载失败')
            break
          default:
            break
        }
      }
    })
    .catch(() => {
      countNum.value = 0
      spinRef.value = false
    })
}

defineExpose({
  action,
})

function getTableData() {
  tableLoading.value = true

  getDeliveryManageTotalsDataFn()

  list.value = {}
  tableData.value = []
  let status = tabList.value.find((it) => it.key == activeKey.value).index
  getDeliveryManageTableData({ ...formData.value, ...page.value, status })
    .then((res) => {
      tableLoading.value = false
      if (res.code === 200) {
        list.value = res.data
        tableData.value = list.value.data
        // tabList.value.find((it) => it.key == activeKey.value).num = res.data.total;
      } else {
        // message.error(res.msg)
      }
    })
    .catch(() => {
      tableLoading.value = false
    })
}

//表格分页事件
const onPageChange = (data) => {
  page.value.page = data
  getTableData()
}

//表格每页条数事件
const onSizeChange = (data) => {
  page.value.size = data.pageSize
  page.value.page = 1
  getTableData()
}

//tabs切换页面
const tabsChange = (e) => {
  columns.value = exportTable(activeKey.value)
  getTableData()
}

watch(locale, () => {
  columns.value = exportTable(activeKey.value)
})

// 添加快递
const AddExpressModalRef = ref()
const submitting = ref(false)
const modalVisible = ref(false)
const currentForm = ref({})

function addExpressFn({ id, shippingSn, businessId }) {
  AddExpressModalRef.value.addExpress()
  currentForm.value = { shippingId: id, shippingSn, businessId }
}

const createExpressFn = (e) => {
  submitting.value = true
  createExpress({ ...e, ...currentForm.value })
    .then((res) => {
      submitting.value = false
      if (res.code === 200) {
        message.success(t('opSuccess'))
        modalVisible.value = false
        getTableData()
      } else {
        // message.error(res.msg)
      }
    })
    .catch(() => {
      submitting.value = false
    })
}
//确认发货关闭之后关闭弹框刷新列表数据
const closeModalUpdateList = () => {
  visibleDetail.value = false
  page.value.page = 1
  getTableData()
}
/* 导出发货明细 */
// const exportShippingSubmitting = ref(false)
// function exportShippingFn({ businessId }) {
//   if (exportShippingSubmitting.value) return
//   exportShippingSubmitting.value = true
//   exportShipping({ businessId })
//     .then((res) => {
//       exportShippingSubmitting.value = false
//       if (res.code == 200) {
//         message.success(t('opSuccess'))
//       }
//     })
//     .catch((err) => {
//       exportShippingSubmitting.value = false
//     })
// }

// 打印
function printFn({ id }) {
  // router.push({
  //   path: '/printInvoice',
  //   query: { id },
  // })
  window.open(`${window.location.origin}/printInvoice?id=${id}`)
}

const toDetail = (type, { id = '' } = {}) => {
  // router.push({ name: 'deliveryManageDetail', query: { type, id } })
  visibleDetail.value = true
  setTimeout(() => {
    deliveryDetailRef.value.action(type, id)
  }, 500)
}

onMounted(() => {
  if (route.meta.isBack) {
    // getDeliveryManageTotalsDataFn();
    getTableData()
  }
})

//点击物流单号
const addressVisible = ref(false)
const logistics = ref([]) //物流轨迹数据
const viewWuliu = (item = '') => {
  console.log(item)
  expressLogsGet({
    number: item,
    // number: '773231117443193',
  }).then((res) => {
    logistics.value = res.data
    addressVisible.value = true
  })
}
//点击物流展示物流轨迹
const showAddressMOdal = (item) => {
  console.log(item, 'item-----item----123123')
  addressVisible.value = true
  viewWuliu(item)
}
onActivated(() => {
  // filterColumns(activeKey.value)
  if (!route.meta.isBack) {
    // getDeliveryManageTotalsDataFn();
    getTableData()
  }
  if (route.meta.isBack && route.meta.isEdit) {
    // getDeliveryManageTotalsDataFn();
    getTableData()
  }
  route.meta.isBack = false
  route.meta.isEdit = false
})
</script>
<style lang="less" scoped>
.manageNav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.addCode {
  text-decoration: underline;
  color: #ff6700;
  cursor: pointer;
}
</style>
